<template>
  <Layout ref="layout" title="プラメギフト" class="pmg600" :showAuLogin="true">
    <div class="pmg600__list">
      <!--バナー-->
      <SpecialFeatureSwipe></SpecialFeatureSwipe>

      <ion-item lines="full ion-no-padding">
        <InfoColumn></InfoColumn>
      </ion-item>
      
      <section>
        <div class="pmg-Page -info">
          <div class="pmg-Info">
            <!-- NOTE: お知らせ START -->
            <h1>【重要】プラメギフト終了のお知らせ</h1>
            <time datetime="2024-12-05">2024年12月5日</time>
            <p></p>
                  <p>いつもプラメギフトをご利用いただき、誠にありがとうございます。<br>このたび、2025年1月31日(金)をもちまして、プラメギフトのサービスの提供を終了させていただくこととなりました。<br>2023年9月のサービス開始より、ご愛顧いただきまして、誠にありがとうございました。<br>お客さまにはご迷惑をおかけいたしますが、何卒ご理解を賜りますようお願い申し上げます。</p>
            <p></p>
            <h3>プラメギフトのサービス終了日</h3>
            <p> 2025年1月31日(金)</p>
            <h3>ギフトの引き換え期限</h3>
            <p>サービス終了に伴う有効期限の変更はございません。</p>
            <!-- NOTE: お知らせ END -->
          </div>
        </div>
      </section>
    </div>

  </Layout>
</template>

<script>
import store from '@/store';
import Layout from '@/components/layout/Layout';
import AuLogin from '@/components/layout/AuLogin';
import BrandList from '@/components/brand/BrandList.vue';
import SpecialFeatureSwipe from '@/components/specialFeature/SpecialFeatureSwipe';
import InfoColumn from '@/components/info/InfoColumn.vue';
import {IonItem} from '@ionic/vue';
import {mapGetters} from 'vuex';
import {emitter} from '@/eventBus';
import {GtmEcommerce, GtmEcommerceItem} from '@//plugins/GtmEcommerce';
import SeasonalEventList from '@/components/seasonalEvent/SeasonalEventList.vue';
import PopularGiftList from "@/components/gift/PopularGiftList.vue";
import SpecialFeatureList from "@/components/specialFeature/SpecialFeatureList.vue";
import CategoryList from "@/components/category/CategoryList.vue";

export default {
  name: 'PMG600',
  components: {
    Layout,
    AuLogin,
    SeasonalEventList,
    BrandList,
    SpecialFeatureSwipe,
    IonItem,
    InfoColumn,
    PopularGiftList,
    SpecialFeatureList,
    CategoryList
  },
  data: () => ({
    filters: {
      'price-from': null,
      'price-to': null,
      'category-ids': null,
      'scene-ids': null,
      'receive-ids': null,
      'filter-count': null,
      'initial-filter': false,
    },
    items: null,
    isFirstLoading: false
  }),
  computed: {
    ...mapGetters(['isAuIdAuthorization', 'isLoading']),
  },
  mounted() {
    const self = this;
    emitter.on('resetFilterListGifts', () => {
      self.filters = {
        'price-from': null,
        'price-to': null,
        'category-ids': null,
        'scene-ids': null,
        'receive-ids': null,
        'filter-count': null,
        'initial-filter': false,
      };
    });
    //フィルターの初期化後、URLパラメータによる初期条件を設定する
    // Promise.resolve()
    //   .then(function () {
    //     return Promise.all([
    //       new Promise((resolve) => {
    //         self.$refs.giftFilterModal.fetchListBudgets()
    //           .then(() => {
    //             resolve();
    //           });
    //       }),
    //       new Promise(async (resolve) => {
    //         await self.$refs.giftFilterModal.fetchListCategories();
    //         resolve();
    //       }),
    //     ])
    //   })
    //   .then(function () {
    //     let hasInitialFilter = self.$refs.giftFilterModal.initialFilter();
    //     self.$refs.giftList.initialSort();
    //     self.$refs.giftFilterModal.clickFilter(hasInitialFilter);
    //   });
  },
  updated() {
    this.isFirstLoading = false
  },
  watch: {
    isLoading(newValue) {
      // データの取得中でない かつ 画面表示後スクロールをしていない場合
      if (newValue === false) {
        const hash = this.$route.hash
        // hashが存在する場合
        if (hash && hash.startsWith("#")) {
          // 1. Vue.jsの非同期処理を利用してDOMの変更が反映されるのを待つ
          this.$nextTick(() => {
            // 2. requestAnimationFrameを使ってアニメーションフレームが開始される前に関数を実行する
            requestAnimationFrame(() => {
              if (this.isFirstLoading === false) {
                // #を取り除く
                const id = hash.substring(1)
                this.scrollTo(id);
              }
            });
          });
        }
      }
    }
  },
  methods: {
    openGiftFilterModal() {
      this.$refs.giftFilterModal.openModal();
    },
    /** ギフト詳細に遷移 */
    async openGiftDetail(giftId) {
      this.$refs.layout.gtmPush('ギフト詳細', `/gifts/${giftId}`);
      this.$router.push({name: 'PMG610', params: {giftId: giftId}});
    },
    /** シーズナルイベントギフト用 */
    async seasonalOpenGiftDetailModal(giftId) {
      this.openGiftDetail(giftId)
    },
    /** 通常ギフト用 */
    async openGiftDetailModal(giftId) {
      this.openGiftDetail(giftId)
      this.ecommerceSelectItemPush(giftId);
    },
    filterListGifts(filters) {
      this.filters = filters;
    },

    // GA(eコマース) event: select_item
    ecommerceSelectItemPush(giftId) {
      const selectGiftData = this.$refs.giftList.getGiftData(giftId);
      let ecommerceItems = [];
      const item = GtmEcommerceItem.getEcommerceItem(selectGiftData);
      ecommerceItems.push(item);
      GtmEcommerce.selectItem(ecommerceItems);
    },
    scrollToGiftList() {
      this.scrollTo('gift-list');
    },
    scrollTo(id) {
      try {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
          });
          this.isFirstLoading = true
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pmg600 {
  &__list {
    .au-login {
      padding: 16px;
    }
  }

  .gift-list {
    margin-top: 24px;

    .gift-label-container {
      padding: 4px 12px;
      margin-bottom: 16px;

      .gift-label {
        font-size: 20px;
        font-weight: 700;
        color: #000;
      }
    }
  }

}

ion-item {
  --background: transparent;
  --inner-padding-end: 0;
  --min-height: 0px;
}

.divider-line {
  width: 100%;
  height: 1px;
  background-color: #D9D9D9;
}
</style>


