<template>
  <div>
    <div class="slide">
      <div class="swiper mySwiper-featureSwipe">
        <div class="swiper-wrapper" @click="showInfo">
          <div
           class="swiper-slide"
           data-page="99"
           data-title="【重要】ギフト販売終了のお知らせ"
           >
            <div
              style="
                background-image: url('/images/importantNotice03.png');
                background-size: cover;
              "
            ></div>
          </div>

          <!-- プラメギフトとは 再表示する可能性もあるのでコメントアウト -->
          <!-- <div
            class="swiper-slide"
            data-page="0"
            data-title="特集タイトル1"
            ref="specialPage0"
          >
            <div
              style="background-image: url('/images/plusmsg-gift01.png'); background-size: cover"
            >
            </div>
          </div> -->
        <!--
          <div
           class="swiper-slide"
           data-page="2"
           data-title="シーン別ギフト特集">
            <div
              style="
                background-image: url('/images/SpecialFeatureBanner/0020.png');
                background-size: cover;
              "
            ></div>
          </div>
          <div
           class="swiper-slide"
           data-page="1"
           data-title="初心者おすすめギフト特集">
            <div
              style="
                background-image: url('/images/SpecialFeatureBanner/SpecialFeatureBanner_20231221.jpg');
                background-size: cover;
              "
            ></div>
          </div> -->


        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import { gtmDataLayerPush } from '@/plugins/GtmHelper';
import { gtmSendClick } from "@/plugins/GtmHelper";
import { getUrl } from '@/plugins/UrlHelper';

export default {
  name: 'SpecialFeatureSwipe',
  components: {},
  data: () => ({
    swiper: null,
  }),
  watch: {
    $route () {
      //特集から戻ってきたときに自動スクロールがなくなる場合がある対策
      if (this.swiper && this.swiper.autoplay && this.$route.name == "PMG600"){
        this.swiper.destroy();
        // this.initSwiper();
      }
    }
  },
  mounted() {
    // this.initSwiper();
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper('.mySwiper-featureSwipe', {
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 8,
      centeredSlides: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        click: (sw) => {
          const clickedIndex = sw.clickedIndex;
          const slide = sw.slides[clickedIndex];
          this.swiper.autoplay.pause();
          if (slide.dataset.page === "0") {
            this.clickSpecialPage0();
          } else if (slide.dataset.page === "99"){
            this.showInfo()
          } else {
            this.clickSlide(slide);
          }
        },
        resize: () => {
          this.swiper.autoplay.start();
        },
      },
      });
    },
    clickSlide(e) {
      const page = e.dataset.page;
      const title = e.dataset.title;
      const virtualPageUrl = getUrl(`/gifts/special-feature/${page}`);
      gtmDataLayerPush(virtualPageUrl, `特集 | ${title}`);
      gtmSendClick(this.$refs['specialPage'+page],'ギフト一覧','特集',title);

      this.$router.push({
        name: 'special-feature',
        params: {
          page: page,
          title: title
        },
      });
    },
    clickSpecialPage0() {
      const url = 'about';
      const title = 'プラメギフトとは';
      const virtualPageUrl = getUrl(`/gifts/static/${url}&${title}`);
      gtmDataLayerPush(virtualPageUrl, title);
      gtmSendClick(this.$refs['specialPage0'],'ギフト一覧','特集',title);

      this.$router.push({
        name: 'PMG640',
        params: {
          url: url,
          title: title,
        },
      });
    },
    showInfo(url, title) {
      const endPoint = process.env.VUE_APP_S3_INFOMATION_URL
      const s3FileName = "2024062801.html"
      url = `https://${endPoint}/${s3FileName}`
      title = "【重要】ギフト販売終了のお知らせ"
      this.$router.push({ name: "PMG630", params: { url: url, title: title } });
    },
  },
};
</script>

<style scoped lang="scss">
.slide {
  .swiper {
    margin-top: 8px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1.6rem;
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 311px;
      height: 164px;
      border-radius: 0.8rem;
      div {
        border-radius: 0.8rem;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-pagination {
      bottom: 0px;
      --swiper-pagination-color: #{$gold2};
      --swiper-pagination-bullet-horizontal-gap: 0.4rem;
    }
  }
}
</style>
