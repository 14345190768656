<template>
  <div class="pmg-Page -guide">
    <section class="pmg-End">
      <p class="pmg-End__text">プラメギフトのサービス提供を2025年1月31日をもって終了します</p>
    </section>
    <header class="pmg-Header">
      <h1 class="pmg-Header__title">
        <span class="pmg-Header__titleImg">
          <img src="../../assets/images/header_title.png" alt="プラメギフト" />
        </span>
        <span class="pmg-Header__titleText">ご利用ガイド</span>
      </h1>
    </header>

    <div class="pmg-Guide">
      <input id="howtosend" type="radio" name="tab_item" checked />
      <label
        class="pmg-GuideTab"
        for="howtosend"
        ref="howtosend"
        @click="clickGA('howtosend','贈り方','')"
        ><span>贈り方</span>
      </label>
      <input id="howtouse" type="radio" name="tab_item" />
      <label
        class="pmg-GuideTab"
        for="howtouse"
        ref="howtouse"
        @click="clickGA('howtouse','受け取り方','')"
        ><span>受け取り方・使い方</span>
      </label>
      <div class="pmg-GuideContent -howtosend">
        <div class="pmg-GuideContent__body">
          <section class="pmg-GuideStep -step1">
            <h3 class="pmg-GuideStep__title">
              <span class="pmg-GuideStep__titleStep">Step</span>
              <span class="pmg-GuideStep__titleNum">1</span>
              <span class="pmg-GuideStep__titleText">ギフトを選ぶ</span>
            </h3>
            <p class="pmg-GuideStep__description">
              もらって嬉しい人気ブランド・人気商品からギフトを選びます。
            </p>
            <div class="pmg-GuideStep__thumb">
              <img src="../../assets/images/guideStep1_thumb_20240321.png" alt="" />
            </div>
            <p class="pmg-GuideStep__annotation">
              ※購入はau, UQ mobile,
              povoのお客さまがご利用いただけます。受け取りはどなたでもご利用いただけます。
            </p>
          </section>
          <section class="pmg-GuideStep -step2">
            <h3 class="pmg-GuideStep__title">
              <span class="pmg-GuideStep__titleStep">Step</span>
              <span class="pmg-GuideStep__titleNum">2</span>
              <span class="pmg-GuideStep__titleText">カードを選ぶ・作る</span>
            </h3>
            <p class="pmg-GuideStep__description">気持ちを演出するギフトカードは2タイプ。</p>
            <div class="pmg-GuideStep__type">
              <div class="pmg-GuideStep__typeItem -normal">
                <h4 class="pmg-GuideStep__typeTitle">通常カード</h4>
                <p class="pmg-GuideStep__typeSubTitle">シンプルに気持ちを<br />贈りたい方向け</p>
                <div class="pmg-GuideStep__typeThumb">
                  <img src="../../assets/images/giftCardType_normal.png" alt="" />
                </div>
                <p class="pmg-GuideStep__typeText">作成不要のシンプルなカード</p>
              </div>
              <div class="pmg-GuideStep__typeItem">
                <h4 class="pmg-GuideStep__typeTitle">リッチカード</h4>
                <p class="pmg-GuideStep__typeSubTitle">写真や動画で<br />演出したい方向け</p>
                <div class="pmg-GuideStep__typeThumb">
                  <img src="../../assets/images/giftCardType_rich.png" alt="" />
                </div>
                <p class="pmg-GuideStep__typeText">
                  写真・動画などを自由に設定できるあなただけのカード
                </p>
              </div>
            </div>
          </section>
          <section class="pmg-GuideStep -step3">
            <h3 class="pmg-GuideStep__title">
              <span class="pmg-GuideStep__titleStep">Step</span>
              <span class="pmg-GuideStep__titleNum">3</span>
              <span class="pmg-GuideStep__titleText">購入する</span>
            </h3>
            <p class="pmg-GuideStep__description">
              購入完了画面に表示されるURLをメッセージアプリやメールなどで送付します。
            </p>
            <div class="pmg-GuideStep__thumb">
              <img
                src="../../assets/images/guideStep3_thumb_20240321.png"
                alt="au かんたん決済 Pontaポイントがたまる・使える！"
              />
            </div>
            <p class="pmg-GuideStep__annotation">
              ※購入はau, UQ mobile,
              povoのお客さまがご利用いただけます。受け取りはどなたでもご利用いただけます。
            </p>
            <p class="pmg-GuideStep__annotation">
              ※auかんたん決済については、
              <a
                href="https://kantan.auone.jp/service/"
                target="_blank"
                ref="kantanKessai"
                @click="clickGA('kantanKessai','かんたん決済','')"
              >
                auかんたん決済の概要
              </a>
              をご覧ください。
            </p>
          </section>
          <section class="pmg-GuideStep -step4">
            <h3 class="pmg-GuideStep__title">
              <span class="pmg-GuideStep__titleStep">Step</span>
              <span class="pmg-GuideStep__titleNum">4</span>
              <span class="pmg-GuideStep__titleText">受け取りURLを贈る</span>
            </h3>
            <p class="pmg-GuideStep__description">
              購入完了画面に表示されるURLをメッセージアプリやメールなどで送付します。
            </p>
            <div class="pmg-GuideStep__thumb">
              <img src="../../assets/images/guideStep4_thumb.png" alt="" />
            </div>
            <p class="pmg-GuideStep__annotation">
              ※受け取り用URLは誰でもアクセスして利用することができます。贈り先やご自分以外の方に共有しないようご注意ください。
            </p>
          </section>
        </div>
      </div>
      <div class="pmg-GuideContent -howtouse">
        <div class="pmg-GuideContent__body">
          <section class="pmg-GuideHowToUse">
            <h3 class="pmg-GuideHowToUse__title">eギフトの場合</h3>
            <p class="pmg-GuideHowToUse__text">
              受け取ったURLをタップするとギフトカードが表示されます。ギフトカード上のギフトボタンをタップして店舗でのご利用画面を表示してください。
            </p>
            <div class="pmg-GuideHowToUse__thumb">
              <img src="../../assets/images/guideHowToUse_egift_thumb.png" alt="" />
            </div>
            <p class="pmg-GuideHowToUse__annotation">
              ※購入はau, UQ mobile,
              povoのお客さまがご利用いただけます。受け取りはどなたでもご利用いただけます。
            </p>
          </section>
          <section class="pmg-GuideHowToUse">
            <h3 class="pmg-GuideHowToUse__title">配送ギフトの場合</h3>
            <p class="pmg-GuideHowToUse__text">
              受け取ったURLをタップするとギフトカードが表示されます。ギフトカード上のギフトボタンをタップして住所、受け取り日時などを入力してください。
            </p>
            <div class="pmg-GuideHowToUse__thumb">
              <img src="../../assets/images/guideHowToUse_real_thumb.png" alt="" />
            </div>
            <p class="pmg-GuideHowToUse__annotation">
              ※購入はau, UQ mobile, povoのお客さまがご利用いただけます。<br />※受け取りはどなたでもご利用いただけます。<br />※商品によって受け取り方法が異なる場合や注意事項がございますので、受け取られたギフトの画面で表示されている内容をご確認ください。
            </p>
          </section>
        </div>
      </div>
    </div>

    <hr class="pmg-Page__hr" />

    <div class="pmg-Faq">
      <h2 class="pmg-Title">よくある質問</h2>
      <details class="pmg-Faq__item">
        <summary
          class="pmg-Faq__label"
          for="q2-07"
          ref="q2-07"
          @click="clickGA('q2-07','FAQ2-07','')"
        >
          複数の人にギフトを贈れますか？
        </summary>
        <div class="pmg-Faq__answer">
          <p>
            1つずつギフトを購入することで贈れます。購入したそれぞれのギフト受け取り用URLを、一人ひとりにお送りください。<br />ギフト受け取り用URLは誰でもアクセスすることができ、URLさえ知っていればギフトを利用することができますので、メッセージアプリのグループトークやSNS等でURLを公開してしまわないようご注意ください。
          </p>
        </div>
      </details>
      <details class="pmg-Faq__item">
        <summary
          class="pmg-Faq__label"
          for="q4-05"
          ref="q4-05"
          @click="clickGA('q4-05','FAQ4-05','')"
        >
        auかんたん決済に進むと「MPLE0272」のエラーが表示される
        </summary>
        <div class="pmg-Faq__answer">
          <p><h3>エラーの内容</h3>プラメギフトでは、お客さまにより安心してご利用いただくために、auかんたん決済ご利用時のSMS認証を導入しており、決済画面へ進むと確認コード付きのSMSをお客さまのスマートフォンへ送付しております（注1）。<br>SMSの送付先は、お客さまのau IDに登録されている代表契約となり、その代表契約がau、UQ mobile、povo以外のご契約となっている場合に本エラーが表示されます（例：代表契約がauひかりのご契約になっている）。<br><h3>対処方法</h3>au IDに登録されている代表契約をau、UQ mobile、povoいずれかのご契約に変更してください。変更方法は下記のauのよくあるご質問ページからご確認をお願いします。<br><a href="https://www.au.com/support/faq/detail/33/a00000000833/" target="_blank">https://www.au.com/support/faq/detail/33/a00000000833/</a><br><br>お客さまのau IDにau、UQ mobile、povoのご契約が紐づいていない場合は、それらのご契約が紐づいている別のau IDでログインしなおしてください。<br><br>（注1）auかんたん決済のSMS認証については、詳しくはauかんたん決済サービスサイトをご覧ください。<a href="https://kantan.auone.jp/guide/sms_authentication/" target="_blank">https://kantan.auone.jp/guide/sms_authentication/</a></p>
        </div>
      </details>
      <details class="pmg-Faq__item">
        <summary
          class="pmg-Faq__label"
          for="q2-03"
          ref="q2-03"
          @click="clickGA('q2-03','FAQ2-03','')"
        >
          購入後にキャンセルはできますか？
        </summary>
        <div class="pmg-Faq__answer">
          <p>購入後のキャンセルや返金は承ることができません。</p>
        </div>
      </details>
      <details class="pmg-Faq__item">
        <summary
          class="pmg-Faq__label"
          for="q3-05"
          ref="q3-05"
          @click="clickGA('q3-05','FAQ3-05','')"
        >
          メッセージアプリのトーク画面でギフト受け取り用URL消してしまうと、ギフトは使えなくなりますか？
        </summary>
        <div class="pmg-Faq__answer">
          <p>
            ギフト受け取り用URLが記載されたトーク（メッセージ）を削除しても、ギフトは削除されません。贈り主の方にご連絡いただき、購入履歴からギフト受け取り用URLを再送していただくようお伝えください。
          </p>
        </div>
      </details>
      <details class="pmg-Faq__item">
        <summary
          class="pmg-Faq__label"
          for="q3-06"
          ref="q3-06"
          @click="clickGA('q3-06','FAQ3-06','')"
        >
          受け取ったギフトを利用しなかった場合、どうなりますか？
        </summary>
        <div class="pmg-Faq__answer">
          <p>
            ギフトを利用しないまま有効期限・引換期限を超えると無効になります。その場合もキャンセルやご返金は承ることができませんのでご注意ください。
          </p>
        </div>
      </details>
      <details class="pmg-Faq__item">
        <summary
          class="pmg-Faq__label"
          for="q1-02"
          ref="q1-02"
          @click="clickGA('q1-02','FAQ1-02','')"
        >
          推奨環境について
        </summary>
        <div class="pmg-Faq__answer">
          <p><h3>プラスメッセージアプリ</h3>　最新バージョン<h3>OS</h3>　・Android OS 11.x以上<br>　・iOS 16.x以上<h3>ブラウザ</h3>　OS標準ブラウザ（Android：Google Chrome、iOS：Safari）の最新バージョン<br>　※上記に当てはまる全ての端末での動作を保障するものではございません。</p>
        </div>
      </details>
      <div class="pmg-Faq__anchor">
        <a
          href="javascript:void(0)"
          @click="clickFaq();clickGA('faqOther','FAQその他','');"
          ref="faqOther"
        >
          その他の質問・お問い合わせはこちら
        </a>
      </div>
    </div>

    <div class="pmg-Notes">
      <h2 class="pmg-Title">注意事項</h2>
      <h3 class="pmg-Title -h3">プラメギフトについて</h3>
      <ul class="pmg-Notes__list">
        <li class="pmg-Notes__item">購入はau, UQ mobile, povoのお客さまがご利用いただけます。</li>
        <li class="pmg-Notes__item">受け取りはどなたでもご利用いただけます。</li>
        <li class="pmg-Notes__item">
          お支払いには、auかんたん決済（通信料合算支払い、au PAY カード支払い、au PAY 残高支払い）
          をご利用いただけます。povo2.0をご契約のお客さまは、プラメギフトでは通信料合算支払いをご利用いただけません。
        </li>
        <li class="pmg-Notes__item">お支払い手続き中にブラウザの戻るボタンや更新ボタンの押下、タブの複製を行なうと、手続きが正常に完了しない場合がございますのでご注意ください。</li>
        <li class="pmg-Notes__item">
          必ず
          <a
            href="javascript:void(0)"
            @click="clickRules();clickGA('rule01','利用規約','')"
            ref="rule01"
          >
            利用規約
          </a>
          をお読みの上、ご利用ください。
        </li>
      </ul>
      <h3 class="pmg-Title -h3">「＋メッセージ（プラスメッセージ）」について</h3>
      <p class="pmg-Notes__paragraph">
        <a
          href="https://www.au.com/mobile/service/plus-message/"
          target="_blank"
          ref="supportDevice"
          @click="clickGA('supportDevice','プラメ対象機種','')"
        >
          「＋メッセージ」アプリの対象機種はこちら
        </a>
        よりご確認ください。2018年11月29日以降にauで発売を開始したケータイ（4GLTE）は公式アカウント機能をご利用いただけないため、サービスをご利用いただけません。
      </p>
      <h3 class="pmg-Title -h3">動作環境・Cookie情報の利用について</h3>
      <p class="pmg-Notes__paragraph">
        こちらのページでご確認ください。<br />
        <a
          href="https://www.kddi.com/terms/requirements/"
          target="_blank"
          ref="requirements"
          @click="clickGA('requirements','動作環境','')"
        >
          https://www.kddi.com/terms/requirements/
        </a>
      </p>
    </div>

    <div class="pmg-Notes" id="purchase">
        <h2 class="pmg-Title">ご購入特典</h2>
        <h3 class="pmg-Title -h3">特典内容</h3>
        <p class="pmg-Notes__paragraph">「プラメギフト」で購入した金額の0.5%をPontaポイントで還元（200円ごとに1ポイント還元）。</p>
        <ul class="pmg-Notes__list -annotation">
          <li class="pmg-Notes__item">※お支払いにはPontaポイントとauかんたん決済がご利用いただけます。</li>
          <li class="pmg-Notes__item">※Pontaポイントで支払った分もポイント還元の対象となります。</li>
          <li class="pmg-Notes__item">※ポイントの還元上限はありません。</li>
        </ul>
        <h3 class="pmg-Title -h3">対象</h3>
        <p class="pmg-Notes__paragraph">2024年3月27日(水)以降のご購入</p>
        <h3 class="pmg-Title -h3">ポイント加算時期</h3>
        <p class="pmg-Notes__paragraph">ご購入日の翌々月末頃まで</p>
        <ul class="pmg-Notes__list -annotation">
          <li class="pmg-Notes__item">※諸事情により遅れる場合もございますのでご了承ください。</li>
        </ul>
        <h3 class="pmg-Title -h3">特典に関する注意事項</h3>
        <ul class="pmg-Notes__list">
          <li class="pmg-Notes__item">加算するPontaポイントは、小数点以下切り捨てとなります。</li>
          <li class="pmg-Notes__item">ポイント加算日までに、ポイント加算対象のau IDに紐づく回線を解約または停止または変更された場合、もしくは別のau IDに統合した場合はポイント加算の対象外となる場合があります。</li>
          <li class="pmg-Notes__item">加算されたPontaポイントを他のau IDへ譲渡したり、au ID間で共有することはできません。</li>
          <li class="pmg-Notes__item">法人名義のau IDは対象外となります。</li>
          <li class="pmg-Notes__item">本特典は予告なく、終了する場合がございます。</li>
          <li class="pmg-Notes__item">本特典の内容及び本注意事項等は予告なく変更されることあります。その場合、変更後の本注意事項が閲覧可能な状態となった時点よりその効力が生じるものとし、以後変更後の本注意事項によるものとします。</li>
          <li class="pmg-Notes__item">本特典は、当社が同時期に実施するキャンペーンとの重複適用ができない場合があります。</li>
        </ul>
      </div>

  </div>
</template>

<script>
import { emitter } from '@/eventBus';
import { gtmDataLayerPush } from '@/plugins/GtmHelper';
import { gtmSendClick } from "@/plugins/GtmHelper";
import { getUrl } from '@/plugins/UrlHelper';

export default {
  name: 'Guide',
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    gtmPush(title, path, params = {}) {
      const virtualPageUrl = getUrl(path, params);
      gtmDataLayerPush(virtualPageUrl, title);
    },
    clickFaq() {
      if (this.modalMode) {
        emitter.emit('showFaq');
      } else {
        emitter.emit('showStaticPage', {
          params: { url: 'faq', title: 'よくある質問・お問い合わせ' },
          query: { ppsm: true },
        });
      }
    },
    clickRules() {
      if (this.modalMode) {
        emitter.emit('showRules');
      } else {
        emitter.emit('showStaticPage', {
          params: { url: 'rules', title: '利用規約' },
          query: { ppsm: true },
        });
      }
    },
    clickGift() {
      if (this.modalMode) {
        emitter.emit('showGifts');
      } else {
        this.gtmPush('ギフトを購入', '/gifts/search');
        this.$router.push({ name: 'PMG660', query: { ppsm: true } });
      }
    },
    clickGA(refName,label2,label3){
      gtmSendClick(this.$refs[refName],'ご利用ガイド',label2,label3);
    }
  },
};
</script>

<style src="@/assets/css/main.css" scoped></style>
