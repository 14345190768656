<template>
  <div class="pmg-Page">
    <section class="pmg-End">
      <p class="pmg-End__text">プラメギフトのサービス提供を2025年1月31日をもって終了します</p>
    </section>
    <h1 class="pmg-Page__title pmg-Page__title-padding">よくある質問</h1>
    <div class="pmg-Faq">
      <nav class="pmg-Faq__menu">
        <ul class="pmg-Faq__menuList">
          <li class="pmg-Faq__menuItem">
            <a href="javascript:void(0)" @click="scrollTo('about')">プラメギフトについて</a>
          </li>
          <li class="pmg-Faq__menuItem">
            <a href="javascript:void(0)" @click="scrollTo('give')">贈る</a>
          </li>
          <li class="pmg-Faq__menuItem">
            <a href="javascript:void(0)" @click="scrollTo('recieve')">受け取る</a>
          </li>
          <li class="pmg-Faq__menuItem">
            <a href="javascript:void(0)" @click="scrollTo('payment')">お支払い</a>
          </li>
          <li class="pmg-Faq__menuItem">
            <a href="javascript:void(0)" @click="scrollTo('history')">履歴</a>
          </li>
        </ul>
      </nav>

      <section class="pmg-Faq__section" id="about">
        <h2 class="pmg-Faq__subTitle">プラメギフトについて</h2>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q1-01"
            ref="q1-01"
            @click="clickGA('q1-01','1-01','')"
          >
            プラメギフトとは？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              au、UQ
              mobile、povoをご利用のお客さまが、「＋メッセージ（プラスメッセージ）」からアクセスできるソーシャルギフトサービスです。詳しくは「
              <a href="javascript:void(0)" @click="clickAbout"> プラメギフトとは </a>
              」をご確認ください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q1-02"
            ref="q1-02"
            @click="clickGA('q1-02','1-02','')"
          >
            推奨環境について
          </summary>
          <div class="pmg-Faq__answer">
            <p><h3>プラスメッセージアプリ</h3>　最新バージョン<h3>OS</h3>　・Android OS 11.x以上<br>　・iOS 16.x以上<h3>ブラウザ</h3>　OS標準ブラウザ（Android：Google Chrome、iOS：Safari）の最新バージョン<br>　※上記に当てはまる全ての端末での動作を保障するものではございません。</p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q1-04"
            ref="q1-04"
            @click="clickGA('q1-04','1-04','')"
          >
            海外でも利用できますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>ご利用いただけません。</p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q1-05"
            ref="q1-05"
            @click="clickGA('q1-05','1-05','')"
          >
            公式アカウントからの通知を停止したい
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              プラメギフト公式アカウントの
              <a href="https://bot.plus-msg.com/?botId=sip:audigitalgift65@maap.plus-msg.com">
                設定画面
              </a>
              を開き、「利用を停止」をタップしてください。
            </p>
          </div>
        </details>
      </section>

      <section class="pmg-Faq__section" id="give">
        <h2 class="pmg-Faq__subTitle">贈る</h2>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q2-01"
            ref="q2-01"
            @click="clickGA('q2-01','2-01','')"
          >
            ギフトの贈り方は？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              「
              <a href="javascript:void(0)" @click="clickGuide"> ご利用ガイド </a>
              」をご確認ください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q2-02"
            ref="q2-02"
            @click="clickGA('q2-02','2-02','')"
          >
            誰に贈ることができますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              スマートフォンをお使いであれば、ご契約の携帯電話会社や、「＋メッセージ」のご利用有無に関わらずどなたにでも贈ることができます。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q2-03"
            ref="q2-03"
            @click="clickGA('q2-03','2-03','')"
          >
            購入後にキャンセルはできますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>購入後のキャンセルや返金は承ることができません。</p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q2-04"
            ref="q2-04"
            @click="clickGA('q2-04','2-04','')"
          >
            商品を購入したはずなのに確認できません
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              購入したギフトは「
              <a href="javascript:void(0)" @click="clickPurchaseHistories"> 購入履歴 </a>
              」にアクセスし、au IDでログインしてください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q2-05"
            ref="q2-05"
            @click="clickGA('q2-05','2-05','')"
          >
            相手の住所がわからなくてもプラメギフトを贈れますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              住所がわからなくても贈ることができます。相手の方に「＋メッセージ」やメール、SMS、その他メッセージアプリで、購入したギフト受け取り用URLを送ってください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q2-06"
            ref="q2-06"
            @click="clickGA('q2-06','2-06','')"
          >
            相手がギフトを利用したか確認することはできますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              商品によっては、ギフト受け取り用URLを開いて表示されるギフト利用画面にて、利用済みかどうかご確認いただけます。ただし、商品・ブランドごとに利用画面の仕様は異なり、確認できない場合もございますのでご了承ください。<br />ギフト利用画面は、「
              <a href="javascript:void(0)" @click="clickPurchaseHistories"> 購入履歴 </a>
              」から該当するギフトの購入詳細画面を開き、「ギフトを自分で利用する」ボタンからご確認ください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q2-07"
            ref="q2-07"
            @click="clickGA('q2-07','2-07','')"
          >
            複数の人にギフトを贈れますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              1つずつギフトを購入することで贈れます。購入したそれぞれのギフト受け取り用URLを、一人ひとりにお送りください。<br />ギフト受け取り用URLは誰でもアクセスすることができ、URLさえ知っていればギフトを利用することができますので、メッセージアプリのグループトークやSNS等でURLを公開してしまわないようご注意ください。
            </p>
          </div>
        </details>
      </section>

      <section class="pmg-Faq__section" id="recieve">
        <h2 class="pmg-Faq__subTitle">受け取る</h2>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q3-01"
            ref="q3-01"
            @click="clickGA('q3-01','3-01','')"
          >
            ギフトはどのようにして受け取れますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              <h3>eギフトの場合</h3>受け取ったURLをタップするとギフトカードが表示されます。ギフトカード上のギフトボタンをタップして店舗でのご利用画面を表示してください。<h3>配送ギフトの場合</h3>受け取ったURLをタップするとギフトカードが表示されます。ギフトカード上のギフトボタンをタップして住所、受け取り日時などを入力してください。<br><br>そのほか、商品によって受け取り方法が異なる場合や注意事項がございますので、受け取られたギフトの画面で表示されている内容をご確認ください。「
              <a href="javascript:void(0)" @click="clickGuide"> ご利用ガイド </a>
              」も併せてご確認ください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q3-02"
            ref="q3-02"
            @click="clickGA('q3-02','3-02','')"
          >
            受け取ったギフトの利用方法がわからない
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              受け取ったギフトURLからギフト利用画面を表示し、そちらに記載されている利用方法・注意事項をご確認ください。<br />記載されている内容にご不明な点がある場合は、各ブランド・ショップにてお問い合わせください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q3-03"
            ref="q3-03"
            @click="clickGA('q3-03','3-03','')"
          >
            店頭でeギフトが使えず引き換えできなかった
          </summary>
          <div class="pmg-Faq__answer">
            <p>ギフトが利用できない場合、以下の可能性があります。<h3>有効期限・引換期限がすぎている</h3>有効期限が過ぎたギフトはご利用いただくことができません。<br>利用されずに有効期限が過ぎた場合も、お取引の取消や返金はできませんのでご注意ください。<h3>店頭の在庫がない・取り扱いがない</h3>店頭に在庫や取り扱いがない場合、利用することはできません。<br>事前にご確認のうえご利用ください。<h3>通信環境が悪い</h3>Wi-Fiのオン/オフを切り替えたり、お使いの端末の再起動をお試しください。</p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q3-04"
            ref="q3-04"
            @click="clickGA('q3-04','3-04','')"
          >
            eギフトを使用していないのに使用済みと表示されます
          </summary>
          <div class="pmg-Faq__answer">
            <p>eギフトの使用した覚えがない場合、以下の可能性があります。<h3>贈り主の方が誤ってご自身で利用された</h3>贈り主がご自身でギフトを利用してしまった可能性があります。可能でしたら贈り主の方に一度ご確認いただけますと幸いです。<h3>ご自分以外の方にギフト受け取り用URLを共有してしまった</h3>ギフト受け取り用URLは誰でもアクセスすることができ、URLさえ知っていればギフトを利用することができます。メッセージアプリのグループトークやSNS等でURLを公開してしまった場合は第三者にギフトを利用されてしまう場合がございます。</p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q3-05"
            ref="q3-05"
            @click="clickGA('q3-05','3-05','')"
          >
            メッセージアプリのトーク画面でギフト受け取り用URL消してしまうと、ギフトは使えなくなりますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              ギフト受け取り用URLが記載されたトーク（メッセージ）を削除しても、ギフトは削除されません。贈り主の方にご連絡いただき、購入履歴からギフト受け取り用URLを再送していただくようお伝えください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q3-06"
            ref="q3-06"
            @click="clickGA('q3-06','3-06','')"
          >
            受け取ったギフトを利用しなかった場合、どうなりますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              ギフトを利用しないまま有効期限・引換期限を超えると無効になります。その場合もキャンセルやご返金は承ることができませんのでご注意ください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q3-07"
            ref="q3-07"
            @click="clickGA('q3-07','3-07','')"
          >
            受け取ったギフトを返金・交換することはできますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>返金・交換はできません。</p>
          </div>
        </details>
      </section>

      <section class="pmg-Faq__section" id="payment">
        <h2 class="pmg-Faq__subTitle">お支払い</h2>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q4-01"
            ref="q4-01"
            @click="clickGA('q4-01','4-01','')"
          >
            支払方法について知りたい
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              Pontaポイントまたはauかんたん決済（通信料合算支払い、au PAY カード支払い、au PAY 残高支払い）をご利用いただけます。<br>※auかんたん決済はau、UQ mobile、povoをご契約のお客さまのみご利用いただけます。<br>※povo2.0をご契約のお客さまは、プラメギフトではauかんたん決済（通信料合算支払い）をご利用いただけません。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q4-02"
            ref="q4-02"
            @click="clickGA('q4-02','4-02','')"
          >
            auかんたん決済とは何ですか
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              au
              IDを利用して、ネットでのお買い物の料金を月々の通信料金と合算してお支払いいただけるオンライン決済サービスです。<br />詳しくはauかんたん決済の
              <a href="https://kantan.auone.jp/service/"> サービス紹介ページ </a>
              をご覧ください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q4-03"
            ref="q4-03"
            @click="clickGA('q4-03','4-03','')"
          >
            Pontaポイントでのお支払い
          </summary>
          <div class="pmg-Faq__answer">
            <p>プラメギフトでは、Pontaポイントを利用することができます。<br>お買い物カゴ画面で利用したいポイント数を設定してください。<br><br>Pontaポイントについて、詳しくは<a href="https://www.au.com/payment/point/">auホームページ</a>をご覧ください。</p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q4-04"
            ref="q4-04"
            @click="clickGA('q4-04','4-04','')"
          >
            auかんたん決済のSMS認証コードを確認すると、ギフトのお支払い画面に戻れない
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              Android版「＋メッセージ」のアプリ内ブラウザでサービスをご利用いただいている場合、「＋メッセージ」に届いたSMS認証コードを確認されると、アプリ内ブラウザが閉じてしまうことでお支払い画面に戻れなくなってしまいます。ご不便おかけしますが、OS標準ブラウザ（Android：Google
              Chrome、iOS：Safari）でサービスをご利用ください。標準ブラウザで利用するには、下記URLをコピーして標準ブラウザのURL欄に入力ください。<br />
              <a href="javascript:void(0)" @click="clickGift">
                https://www.egift.plus-msg.auone.jp/gifts/
              </a>
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q4-05"
            ref="q4-05"
            @click="clickGA('q4-05','4-05','')"
          >
          auかんたん決済に進むと「MPLE0272」のエラーが表示される
          </summary>
          <div class="pmg-Faq__answer">
              <p><h3>エラーの内容</h3>プラメギフトでは、お客さまにより安心してご利用いただくために、auかんたん決済ご利用時のSMS認証を導入しており、決済画面へ進むと確認コード付きのSMSをお客さまのスマートフォンへ送付しております（注1）。<br>SMSの送付先は、お客さまのau IDに登録されている代表契約となり、その代表契約がau、UQ mobile、povo以外のご契約となっている場合に本エラーが表示されます（例：代表契約がauひかりのご契約になっている）。<br><h3>対処方法</h3>au IDに登録されている代表契約をau、UQ mobile、povoいずれかのご契約に変更してください。変更方法は下記のauのよくあるご質問ページからご確認をお願いします。<br><a href="https://www.au.com/support/faq/detail/33/a00000000833/" target="_blank">https://www.au.com/support/faq/detail/33/a00000000833/</a><br><br>お客さまのau IDにau、UQ mobile、povoのご契約が紐づいていない場合は、それらのご契約が紐づいている別のau IDでログインしなおしてください。<br><br>（注1）auかんたん決済のSMS認証については、詳しくはauかんたん決済サービスサイトをご覧ください。<a href="https://kantan.auone.jp/guide/sms_authentication/" target="_blank">https://kantan.auone.jp/guide/sms_authentication/</a></p>
            </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
             class="pmg-Faq__label" 
             for="q4-06" 
             ref="q4-06"
             @click="clickGA('q4-06','4-06','')"
          >
          ポイント還元（ご購入特典）について
          </summary>
            <div class="pmg-Faq__answer">
              <p>プラメギフトでは、購入した金額の0.5%をPontaポイントで還元（200円ごとに1ポイント還元）しています。<br>
                詳しくは<a href="javascript:void(0)" @click="scrollToPurchase">サービス紹介ページ</a>をご確認ください。
              </p>
            </div>
          </details>
      </section>

      <section class="pmg-Faq__section" id="history">
        <h2 class="pmg-Faq__subTitle">履歴</h2>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q5-01"
            ref="q5-01"
            @click="clickGA('q5-01','5-01','')"
          >
            注文番号はどこに記載されていますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              購入履歴に記載がございますので、「
              <a href="javascript:void(0)" @click="clickPurchaseHistories"> 購入履歴 </a>
              」にアクセスし、au IDでログインしてご確認ください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q5-02"
            ref="q5-02"
            @click="clickGA('q5-02','5-02','')"
          >
            購入したギフトはどこで確認できますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              購入したギフトは「
              <a href="javascript:void(0)" @click="clickPurchaseHistories"> 購入履歴 </a>
              」にアクセスし、au IDでログインしてください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q5-03"
            ref="q5-03"
            @click="clickGA('q5-03','5-03','')"
          >
            受け取ったギフトはどこで確認できますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>
              贈り主の方からギフト受け取り用URLの通知を受け取った、「＋メッセージ」やメッセージアプリのトーク画面、メールなどの履歴をご確認ください。
            </p>
          </div>
        </details>
        <details class="pmg-Faq__item">
          <summary
            class="pmg-Faq__label"
            for="q5-04"
            ref="q5-04"
            @click="clickGA('q5-04','5-04','')"
          >
            購入履歴は削除できますか？
          </summary>
          <div class="pmg-Faq__answer">
            <p>削除できません。</p>
          </div>
        </details>
      </section>

      <section class="pmg-Faq__section">
        <h2 class="pmg-Faq__subTitle">本サービスに関するお問い合わせ</h2>
        <p class="pmg-Faq__text">
          本サービスに関するお問い合わせは
          <a
            href="https://www.au.com/support/inquiry/message/"
            ref="customerCenter"
            @click="clickGA('customerCenter','4-お客さまセンター','')"
          >
            お客さまセンター
          </a>
          にご連絡ください。
        </p>
      </section>
    </div>
  </div>
</template>

<script>
import { emitter } from '@/eventBus';
import { gtmDataLayerPush } from '@/plugins/GtmHelper';
import { gtmSendClick } from "@/plugins/GtmHelper";
import { getUrl } from '@/plugins/UrlHelper';

export default {
  name: 'Faq',
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    scrollTo(id) {
      try {
        const el = document.getElementById(id);
        el.scrollIntoView({
          behavior: 'smooth',
        });
      } catch (error) {
        console.error(error);
      }
    },
    gtmPush(title, path, params = {}) {
      const virtualPageUrl = getUrl(path, params);
      gtmDataLayerPush(virtualPageUrl, title);
    },
    clickAbout() {
      emitter.emit('showStaticPage', {
        params: { url: 'about', title: 'プラメギフトとは' },
        query: { ppsm: true },
      });
    },
    clickGuide() {
      emitter.emit('showStaticPage', {
        params: { url: 'guide', title: 'ご利用ガイド' },
        query: { ppsm: true },
      });
    },
    clickPurchaseHistories() {
      this.gtmPush('購入履歴', '/purchase-histories');
      this.$router.push({ name: 'PMG800', query: { ppsm: true } });
    },
    clickGift() {
      this.gtmPush('ギフトを購入', '/gifts');
      this.$router.push({ name: 'PMG600', query: { ppsm: true } });
    },
    clickGA(refName,label2,label3){
      gtmSendClick(this.$refs[refName],'FAQ',label2,label3);
    },
    scrollToPurchase() {
      const url = 'about';
      const title = 'プラメギフトとは';
      this.$router.push({
        name: 'PMG640', 
        hash: '#purchase',
        params: {
          url: url,
          title: title,
        },
      });
    },
  },
};
</script>

<style src="@/assets/css/main.css" scoped></style>
